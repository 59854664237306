<template>
  <h1 class="py-5 text-center">
    {{ $t('page-not-found') }}
  </h1>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>
